
.ServicePage .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--backgroundSub);
    padding: 64px 4px;
    border-radius: 4px;

    border: 2px solid transparent;
    cursor: pointer;
    transition: 0.4s;
}
.ServicePage .item:hover {
    border: 2px solid var(--red);
  background: var(--background);
    transition: 0.2s;
}

.ServicePage .item i {
    font-size: 60px;
    transition: 0.4s;
    color: #fff;
}
.ServicePage .item:hover i {
    transition: 0.2s;
    color: var(--red)
}

.ServicePage .item .name {
    font-size: 24px;
    transition: 0.4s;
}
.ServicePage .item:hover .name {
    transition: 0.2s;
    color: var(--red)
}
