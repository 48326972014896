.nav-top  {
  padding: 24px 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
}

.nav-top img.logo {
  max-height: 80px;
  height: auto;
  max-width: 100%;
  transition: 0.4s;
}

.nav-top img.logo:active {
    transform: translateY(4px);
    transition: 0.1s;
}


.nav-top .split {
  height: 44px;
  width: 2px;
background: var(--red);
margin:0  8px;
}
.nav-top img.weberLogo {
 margin-right: 8px;
}
.nav-top a {
   text-transform: uppercase;
   padding: 8px;
   font-weight: 400;
   letter-spacing: 1px;
   font-size: 16px;
   color: #fff;
   text-decoration: none;
}
.nav-top a:hover {
  text-decoration: underline;
}
.nav-top a.active {
    color: var(--red);
       text-decoration: none !important;
}




.nav-top .menu-toggle {
    background: #111;
    width: 100%;
    padding: 8px 16px;
    color: #ddd;
    margin: 12px 0;
}
.nav-top .menu-toggle i {
    color: #ddd;
}

.nav-top .menu-toggle > div {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}



.nav-top .menu-content {
   transform: scale(0.2) translateX(-100%);
   opacity: 0.2;
    max-height: 0;
    max-width: 0;
    overflow: hidden;
    transition: 0.4s;
}


.nav-top .menu-content.true {
    max-height: 50vh;
    max-width: 10000px;
    transition: 0.4s;
    transform: none;
       opacity: 1;
}
@media (min-width: 992px) { 
    .nav-top .menu-content {
   max-height: 50vh;
    max-width: 10000px;
    transition: 0.4s;
    transform: none;
       opacity: 1;
    }
 }

