body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--background);
  color: var(--textSub);

  font-family: "Roboto", sans-serif;

  overflow-x: hidden;
  overflow-y: auto;
}
* {
  outline: none !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  --background: #000;
  --backgroundSub: #111;
  --red: red; /*Auf Kundenwunscht (email vom 16.10.20 geändert) --red: #9b2423;*/
  /*--red: #e00c18; /*Auf Kundenwunscht (email vom 16.10.20 geändert) --red: #9b2423;*/

  /* 50% */
  --redLight: #fff;
  --redLightHover: red;

  --textMain: #aaa;
  --textSub: #aaa;
}

button {
  background: var(--red);
  border: none;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 4px 12px;
  font-size: 16px;
  cursor: pointer;

  transition: 0.6s;
}
button::selection,
img::selection {
  background: transparent;
}
button:hover {
  transition: 0.2s;
  background: var(--redLightHover);
}
button:active {
  transition: 0.1s;
  transform: translateX(4px);
}
.mt-10 {
  margin-top: 64px;
}
h1 {
  font-size: 64px;
  font-weight: 700;
  margin-bottom: 24px;
  color: #fff;
}
h2 {
  color: var(--red);
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 400;
}
h3 {
  color: #fff;
  font-size: 26px;
  font-weight: 500;
}
a {
  color: var(--redLight);
  cursor: pointer;
  transition: 0.5s;
  text-decoration: none;
  font-weight: 400;
}
a:hover {
  color: var(--redLightHover);
  cursor: pointer;
  transition: 0.2s;
  display: inline-block;
}
p {
  color: #ddd;
  font-size: 19px;
  font-weight: 300;
  white-space: pre-wrap;
}
p small {
  font-size: 16px;
}

.links {
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

#root {
  width: 100vw;
  overflow-x: hidden;
}

html,
body {
  overflow-x: hidden;
  overflow-y: auto;
}
.fas {
  color: var(--red);
}
.fad {
  --fa-primary-color: var(--red);
  --fa-secondary-color: #fff;
  --fa-secondary-opacity: 0.8;
}
.PageAnimation > div {
  animation: fadeIn 0.2s ease-in;
}
svg {
  max-width: 100%;
}

/* Galeire n/s */
.awssld__bullets {
  bottom: 45px !important;
  /* margin-top: -16px !important; */
}
.awssld__bullets button {
  border-radius: 0px !important;
  height: 8px !important;
}
.awssld__bullets .awssld__bullets--active {
  background: var(--red) !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0.8;
    transform: translateY(-12px) scaleX(0.9) scaleY(0.9);
  }
  100% {
    opacity: 1;
  }
}

.carousel-indicators {
  transform: translateY(44px);
}

.nav-top .weberLogo {
  height: 24px;
  border-radius: 3px;
}
/* Sidebar  */

.sidebar {
  background: var(--backgroundSub);
  align-self: flex-start;

  border-left: 8px solid #fff;
}
.sidebar .item {
  margin-bottom: 44px;
}

/* Main COntent */

.main-content {
  /* border-top: 4px solid var(--red); */
}

/* footer */
footer {
  background: var(--backgroundSub);
  padding: 32px 0;
  margin-top: 64px;
}
footer a {
  color: #fff;
}
footer .DashdesignLogo {
  height: 52px;
}

.red {
  color: red;
}
a {
  cursor: pointer;
  transition: 0.4s;
}
/* a:hover{
  transition: 0.2s;
  transform: scale(1.1);
} */
